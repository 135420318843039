import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/v2/templates/Layout';
import { Section } from '../../components/v2/templates/Section';
import useStoryblok from '../../lib/storyblok';
import Bloks from '../../components/atoms/Bloks';
import Tabs from '../../components/v2/organisms/Tabs';
import { StoryblokPage } from '../../types';

const Sandbox: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;
  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Section curved hero noGrid curveColor="tertiary">
        <Bloks bloks={content.hero} />
      </Section>

      <Bloks bloks={content.sections} />

      <Tabs bloks={content.tabs} />

      <Bloks bloks={content.sectionsAfterTabs} />
    </Layout>
  );
};

export default Sandbox;

export const query = graphql`
  query Sandbox {
    storyblokEntry(full_slug: { eq: "sandbox" }) {
      content
      name
    }
  }
`;
